import React, { Fragment } from "react";

import {
    Button,
    DialogTitle,
    TextField,
    Fade
} from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { dialogTheme } from "legacy-js/react/materialThemeOverrides";
import {
    BeautifulDialog,
    DialogContent,
    ShowErrorDialog,
    ShowMessageDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import Api from "js/core/api";
import { CustomerType } from "legacy-common/constants";
import { app } from "js/namespaces";
import { User } from "js/core/models/user";
import { ds } from "js/core/models/dataService";
import { trackActivity } from "js/core/utilities/utilities";
import { withFirebaseUser } from "legacy-js/react/views/Auth/FirebaseUserContext";
import { Gap10, Gap20, Gap30 } from "legacy-js/react/components/Gap";
import { renderReactDialog, renderReactDialogFromJSX } from "legacy-js/react/renderReactRoot";
import VerifyEmailDialog from "legacy-js/react/views/MarketingDialogs/VerifyEmailDialog";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { getStaticUrl, isDevelopment } from "legacy-js/config";
import { StandalonePage } from "legacy-js/react/views/Onboarding/Standalone";
import moment from "moment";

import "css/controls.scss";
import "css/dialogs.scss";
import { RemoveSplashScreen } from "legacy-js/editor/SplashScreen";
import * as analytics from "legacy-js/analytics";

const checkIcon = getStaticUrl("/images/auth/done-check.gif");
const eduIcon = getStaticUrl("/images/onboarding/edu.png");

class CheckoutEdu extends React.Component {
    state = {
        isReady: false,
        schoolName: "",
        schoolUrl: "",
        schoolGrade: ""
    };

    async componentDidMount() {
        RemoveSplashScreen(true);

        const [trialDetailResponse, subscriptionResponse] = await Promise.all([
            Api.trialDetail.get({ t: new Date().getTime() }),
            Api.subscriptions.get({ customer_type: CustomerType.INDIVIDUAL })
        ]);

        const { currentPlan, analyticsCurrentPlan } = trialDetailResponse;

        //If user has an individual subscription and their plan is not cancelled show dialog to send them back to library.
        if (subscriptionResponse.length && subscriptionResponse[0].id && !subscriptionResponse[0].cancel_at_period_end) {
            ShowMessageDialog({
                title: "Are you sure about this?",
                message: "You are already paying for a Pro plan. Let’s take you to your presentation library.",
                onClick: () => {
                    window.location = "/";
                },
                onClose: () => {
                    window.location = "/";
                }
            });
        }

        if (currentPlan == "edu") {
            await ShowErrorDialog({
                title: "Oops, you already have the Education Plan",
                message: <Fragment>This account has already been setup with the free education plan. If you are having problems with your education plan, please contact us at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a></Fragment>,
            });
            window.location = "/";
            return;
        }

        // load the User model
        app.user = new User({ id: this.props.firebaseUser.uid }, { autoLoad: false });
        ds.checkoutSetup();
        await app.user.load();
        await analytics.identify(app.user.getAuthUser());

        app.user.analyticsPersonalPlan = analyticsCurrentPlan;

        // check if user has verified email
        let authUser = app.user.getAuthUser();
        if (!authUser.emailVerified) {
            renderReactDialog(VerifyEmailDialog, {
                callback: async complete => {
                    if (complete) {
                        await ShowErrorDialog({
                            title: "We sent an email to you so you can verify your email address.",
                            message: "Once you've verified your email address, you can proceed with applying for the Edu plan again."
                        });
                    }
                    window.location = "/";
                    return;
                }
            });
            return;
        }

        const props = {
            "current_status": currentPlan,
            "workspace_id": "personal",
        };
        trackActivity("Upgrade", "EducationPlanStart", null, null, props, { audit: true });

        if (!app.user.isEligibleForEduPlan()) {
            await ShowErrorDialog({
                title: "Oh, no! We're unable to setup your account with the Education Plan",
                message: <>
                    Your email doesn't appear to be a valid school email address.<br /><br />
                    You can try signing up using your school email, or you can contact us at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a> so we can help you directly.
                </>
            });
            if (!isDevelopment) {
                window.location = "/";
                return;
            }
        }

        this.setState({
            isReady: true,
        });
    }

    handleChangeField = (field, value) => {
        this.setState({
            [field]: value,
            [field + "Error"]: false
        });
    }

    handleEduPlan = async () => {
        let { schoolName, schoolUrl, schoolGrade } = this.state;

        let schoolNameError = schoolName.trim() == "";
        let schoolUrlError = schoolUrl.trim() == "";
        let schoolGradeError = schoolGrade.trim() == "";

        this.setState({
            schoolNameError, schoolUrlError, schoolGradeError
        });

        if (schoolNameError || schoolUrlError || schoolGradeError) {
            ShowErrorDialog({
                title: "Please fill out all the fields so we can verify your eligibility for the Education Plan"
            });
            return;
        }

        let results = await Api.eduPlan.post({
            schoolName, schoolUrl, schoolGrade
        });

        if (results.success) {
            const props = {
                schoolName: schoolName,
                schoolGrade: schoolGrade,
                schoolUrl: schoolUrl,
                workspace_id: "personal",
            };
            trackActivity("Upgrade", "EducationPlanComplete", null, null, props, { audit: true });

            renderReactDialogFromJSX(
                <BeautifulDialog preventClose>
                    <DialogContent>
                        <div id="successful_purchase">
                            <Gap20 />
                            <div id="big_icon">
                                <img src={checkIcon} width={75} height={75} />
                            </div>
                            <div id="message">You are now enrolled in the Beautiful.ai Education plan!</div>
                            <div className="receipt">
                                Your membership will be valid until {moment().add(1, "Y").format("LL")}*.  You must re-enroll after one year to continue using the Beautiful.ai Education plan.
                            </div>
                            <Gap20 />
                            <Button
                                color="primary"
                                onClick={() => {
                                    window.location = "/";
                                }}
                            >Refresh</Button>
                        </div>
                    </DialogContent>
                </BeautifulDialog>
            );
        } else {
            ShowErrorDialog({
                title: "Sorry, we were unable to add the education plan to your account",
                message: results.error
            });
        }
    }

    closeDialog = () => {
        window.location = "/";
    }

    render() {
        let {
            schoolName,
            schoolUrl,
            schoolGrade,
            schoolNameError,
            schoolUrlError,
            schoolGradeError,
            isReady,
        } = this.state;

        let form = null;
        if (isReady) {
            const submitDisabled = (
                !schoolName.trim() ||
                !schoolUrl.trim() ||
                !schoolGrade.trim() ||
                !!schoolNameError ||
                !!schoolUrlError ||
                !!schoolGradeError
            );

            form = (
                <MuiThemeProvider theme={dialogTheme}>
                    <BeautifulDialog
                        closeDialog={this.closeDialog}
                        hideBackdrop
                    >
                        <FlexBox middle fillWidth style={{ paddingTop: 20, marginBottom: -30 }}>
                            <img src={eduIcon} width={75} height={75} />
                        </FlexBox>
                        <DialogTitle>
                            Apply for the free Beautiful.ai Education Plan
                        </DialogTitle>
                        <DialogContent>
                            Please verify that you are an eligible student by filling out this form.
                            <Gap20 />
                            <TextField
                                label="School Name"
                                value={schoolName}
                                fullWidth required
                                onChange={event => this.handleChangeField("schoolName", event.target.value)}
                                error={schoolNameError}
                            />
                            <Gap10 />
                            <TextField
                                label="School Website"
                                value={schoolUrl}
                                fullWidth required
                                onChange={event => this.handleChangeField("schoolUrl", event.target.value)}
                                error={schoolUrlError}
                            />
                            <Gap10 />
                            <TextField
                                label="Current Grade or Year"
                                value={schoolGrade}
                                fullWidth required
                                onChange={event => this.handleChangeField("schoolGrade", event.target.value)}
                                error={schoolGradeError}
                            />
                            <Gap30 />
                            <p>
                                Your use of and access to the Beautiful.ai Education plan is governed by our <a href="https://www.beautiful.ai/terms-of-service" target="_blank">Terms of Service</a>.
                                By submitting this form and using the Beautiful.ai Education plan, you confirm that you are a student.
                            </p>
                            <p>
                                For more information on the Beautiful.ai Education Plan, click <a href="https://www.beautiful.ai/education" target="_blank">here</a>.
                            </p>

                            <Gap30 />
                            <FlexBox middle fillWidth>
                                <Button onClick={this.closeDialog}>Cancel</Button>
                                <Gap20 />
                                <Button color="primary" disabled={submitDisabled} onClick={this.handleEduPlan}>Submit</Button>
                            </FlexBox>

                        </DialogContent>
                    </BeautifulDialog>
                </MuiThemeProvider>
            );
        }

        return (
            <Fade in={true} timeout={750}>
                <StandalonePage>
                    {form}
                </StandalonePage>
            </Fade>
        );
    }
}

export default withFirebaseUser(CheckoutEdu);
